import axios from 'axios';
import React, { useContext, useState } from 'react';
import { FaLock, FaUserAlt } from 'react-icons/fa';
import Context from '../store/context';
import '../styles/Login.css';
import useWindowsDimensions from '../useWindowsDimensions';

export default function Login({ setToken }) {
	const [username, setUserName] = useState();
	const [password, setPassword] = useState();
	const [missingFields, setMissingFields] = useState(false);
	const [wrongPassword, setWrongPassword] = useState(false);
	const [noUser, setNoUser] = useState(false);

	//UseContext to UseGlobal State
	const { state } = useContext(Context);

	const windowDimensions = useWindowsDimensions();
	const width = windowDimensions.width;

	const handleSubmit = (e) => {
		e.preventDefault();

		axios
			.post(
				'https://meetingpointaveiro.2bconnect.pt/teste/php/Users/auth.php',
				{
					user: username,
					password: password,
				}
			)
			.then((res) => {
				console.log('auth: ' + res.data.msg);
				console.log(res.data);
				if (res.data.success === 1) {
					axios
						.post(
							'https://meetingpointaveiro.2bconnect.pt/teste/php/Users/get-user.php',
							{
								user: username,
							}
						)
						.then((res) => {
							async function updateStore() {
								if (res.data.userData[0].role == 'admin') {
									state.isAdmin = true;
								}

								state.name = res.data.userData[0].name;
								state.user = res.data.userData[0].user;
								state.role = res.data.userData[0].role;
                                sessionStorage.setItem('role', state.role);
                                sessionStorage.setItem('name', state.name);
                                sessionStorage.setItem('user', state.user);
							}

							updateStore().then((res) => {
								setToken({ token: 'true' });
							});
						});
				}
				if (res.data.success === 2) {
					setWrongPassword(true);
				}
				if (res.data.success === 3) {
					setNoUser(true);
				}
				if (res.data.success === 4) {
					setMissingFields(true);
				}
			});
	};

	const displayMissingFieldsWarning = missingFields
		? 'Por favor, preencha todos os campos!'
		: null;
	const displayWrongPasswordWarning = wrongPassword
		? 'A palavra-passe está errada!'
		: null;
	const displayNoUserWarning = noUser ? 'Este utilizador não existe!' : null;

	return (
		<main className='login-main'>
			{(() => {
				if (width < 582) {
					return (
						<div className='logo-section'>
							<span className='text'>MEETING POINT</span>
						</div>
					);
				} else {
					return null;
				}
			})()}

			<form className='login-form' onSubmit={handleSubmit}>
				<div className='input-container'>
					<span className='user-icon'>
						{' '}
						<FaUserAlt />{' '}
					</span>
					<input
						className='username-input'
						type='text'
						id='username'
						name='username'
						placeholder='Username'
						onChange={(e) => setUserName(e.target.value)}></input>
				</div>

				<div className='input-container'>
					<span className='user-icon'>
						{' '}
						<FaLock />{' '}
					</span>
					<input
						className='username-input'
						type='password'
						id='password'
						name='password'
						placeholder='Password'
						onChange={(e) => setPassword(e.target.value)}></input>
				</div>

				{displayMissingFieldsWarning}
				{displayWrongPasswordWarning}
				{displayNoUserWarning}

				<div className='button-container'>
					<button type='submit'>Entrar</button>
				</div>
			</form>

			{(() => {
				if (width > 582) {
					return (
						<div className='logo-section'>
							<span className='text'>MEETING POINT</span>
						</div>
					);
				} else {
					return null;
				}
			})()}
		</main>
	);
}
