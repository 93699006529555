import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Arrow, Circle, Layer, Line, Stage, Text } from 'react-konva';

function WindSensor() {
	const [windDirection, setWindDirection] = useState(0);

	const [windVelocity, setWindVelocity] = useState(0);

	const [lastUpdate, setLastUpdate] = useState();

	useEffect(() => {
		setInterval(() => {
			fetchWindData();
		}, 3000);
	}, []);

	useEffect(() => {
		fetchWindData();
	});

	useEffect(() => {
		console.log("windDirection's new value: " + windDirection);
	}, [windDirection]);

	const fetchWindData = () => {
		axios
			.post(
				'https://meetingpointaveiro.2bconnect.pt/teste/php/middleware/read-wind-data.php'
			)
			.then((res) => {
				console.log(res);
				setWindDirection(res.data.windData[0].direction);
				setWindVelocity(res.data.windData[0].velocity);
				setLastUpdate(res.data.windData[0].datatime);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const useStyles = makeStyles({
		table: {
			maxWidth: '500px',
		},
		head: {
			backgroundColor: 'black',
			color: 'white',
		},
		tableWrapper: {
			display: 'flex',
			justifyContent: 'center',
		},
	});

	function createData(name, value) {
		return { name, value };
	}

	const rows = [
		createData('Velocidade (km/h)', windVelocity),
		createData('Última actualização', lastUpdate),
	];

	const classes = useStyles();

	return (
		<main className='content-wrap-wind'>
			<Stage width={window.innerWidth} height={window.innerHeight / 1.5}>
				<Layer>
					<Circle
						x={window.innerWidth / 2}
						y={window.innerHeight / 3}
						radius={150}
						stroke={'black'}
					/>
					<Circle
						x={window.innerWidth / 2}
						y={window.innerHeight / 3}
						radius={125}
						stroke={'black'}
					/>
					<Circle
						x={window.innerWidth / 2}
						y={window.innerHeight / 3}
						radius={100}
						stroke={'black'}
					/>
					<Circle
						x={window.innerWidth / 2}
						y={window.innerHeight / 3}
						radius={75}
						stroke={'black'}
					/>
					<Circle
						x={window.innerWidth / 2}
						y={window.innerHeight / 3}
						radius={50}
						stroke={'black'}
					/>
					<Circle
						x={window.innerWidth / 2}
						y={window.innerHeight / 3}
						radius={25}
						stroke={'black'}
					/>
					<Circle
						x={window.innerWidth / 2}
						y={window.innerHeight / 3}
						radius={10}
						stroke={'red'}
						fill={'red'}
					/>

					<Arrow
						className={'arrow'}
						x={window.innerWidth / 2}
						Y={window.innerHeight / 3}
						stroke='red'
						strokeWidth={5}
						points={[0, 0, 0, -145]}
						rotation={windDirection}
					/>

					<Line
						stroke='black'
						strokeWidth={0.5}
						x={window.innerWidth / 2}
						y={window.innerHeight / 3}
						points={[0, 0, 0, -150]}
					/>

					<Line
						stroke='black'
						strokeWidth={0.5}
						x={window.innerWidth / 2}
						y={window.innerHeight / 3}
						points={[0, 0, 0, -150]}
						rotation={45}
					/>

					<Line
						stroke='black'
						strokeWidth={0.5}
						x={window.innerWidth / 2}
						y={window.innerHeight / 3}
						points={[0, 0, 0, -150]}
						rotation={90}
					/>

					<Line
						stroke='black'
						strokeWidth={0.5}
						x={window.innerWidth / 2}
						y={window.innerHeight / 3}
						points={[0, 0, 0, -150]}
						rotation={135}
					/>

					<Line
						stroke='black'
						strokeWidth={0.5}
						x={window.innerWidth / 2}
						y={window.innerHeight / 3}
						points={[0, 0, 0, -150]}
						rotation={180}
					/>

					<Line
						stroke='black'
						strokeWidth={0.5}
						x={window.innerWidth / 2}
						y={window.innerHeight / 3}
						points={[0, 0, 0, -150]}
						rotation={225}
					/>

					<Line
						stroke='black'
						strokeWidth={0.5}
						x={window.innerWidth / 2}
						y={window.innerHeight / 3}
						points={[0, 0, 0, -150]}
						rotation={270}
					/>

					<Line
						stroke='black'
						strokeWidth={0.5}
						x={window.innerWidth / 2}
						y={window.innerHeight / 3}
						points={[0, 0, 0, -150]}
						rotation={315}
					/>

					<Text
						text='N'
						x={window.innerWidth / 2}
						y={window.innerHeight / 3}
						offsetY={180}
						offsetX={8}
						fontStyle='bold'
						fontSize={15}
					/>

					<Text
						text='NE'
						x={window.innerWidth / 2}
						y={window.innerHeight / 3}
						offsetY={180}
						offsetX={8}
						fontStyle='bold'
						fontSize={15}
						rotation={45}
					/>

					<Text
						text='E'
						x={window.innerWidth / 2}
						y={window.innerHeight / 3}
						offsetY={180}
						offsetX={8}
						fontStyle='bold'
						fontSize={15}
						rotation={90}
					/>

					<Text
						text='SE'
						x={window.innerWidth / 2}
						y={window.innerHeight / 3}
						offsetY={180}
						offsetX={8}
						fontStyle='bold'
						fontSize={15}
						rotation={135}
					/>

					<Text
						text='S'
						x={window.innerWidth / 2}
						y={window.innerHeight / 3}
						offsetY={180}
						offsetX={8}
						fontStyle='bold'
						fontSize={15}
						rotation={180}
					/>

					<Text
						text='SO'
						x={window.innerWidth / 2}
						y={window.innerHeight / 3}
						offsetY={180}
						offsetX={8}
						fontStyle='bold'
						fontSize={15}
						rotation={225}
					/>

					<Text
						text='O'
						x={window.innerWidth / 2}
						y={window.innerHeight / 3}
						offsetY={180}
						offsetX={8}
						fontStyle='bold'
						fontSize={15}
						rotation={270}
					/>

					<Text
						text='NO'
						x={window.innerWidth / 2}
						y={window.innerHeight / 3}
						offsetY={180}
						offsetX={8}
						fontStyle='bold'
						fontSize={15}
						rotation={315}
					/>
				</Layer>
			</Stage>

			<div className={classes.tableWrapper}>
				<TableContainer className={classes.table} component={Paper}>
					<Table size='small' aria-label='simple table'>
						<TableBody>
							{rows.map((row) => (
								<TableRow className='table-row' key={row.name}>
									<TableCell component='th' scope='row'>
										{row.name}
									</TableCell>
									<TableCell align='left'>
										{row.value}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</main>
	);
}

export default WindSensor;
