import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";

const useStyles = makeStyles(() => ({
    buttons: {
      color: 'white',
      fontSize: '18px',
      //maxWidth: '70px',
      //marginRight: '1%',
      backgroundColor: 'red',
      '&:hover': {
        backgroundColor: 'firebrick'
     }
    }
  }));

function CleanSound(props) {
    
    const classes = useStyles();


    return (        
      
          <Button disabled={sessionStorage.getItem('role') != 'admin'} size="small" variant="contained" className={classes.buttons} onClick={() => { window.confirm('Tem a certeza que pretende limpar os sons?') && props.uncheckState() } }>
            Limpar Sons
          </Button>           
         
      );

}

export default CleanSound;