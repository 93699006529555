import React, { useContext, useState } from 'react';
import useWindowsDimensions from '../../useWindowsDimensions';
import FullBar from './FullBar';
import SimplerBar from './SimplerBar';

export default function TopAppBar() {
	const windowDimensions = useWindowsDimensions();
	const width = windowDimensions.width;

	return <>{width > 846 ? <FullBar /> : <SimplerBar />}</>;
}
