import React from 'react'
import '../styles/ConfigMessage.css'


//Component used to display titles on WebApp
function DisplayMessage(props) {
    return (
        <div className="config-message">
            <h2>{props.message}</h2>
        </div>
    )
}

export default DisplayMessage
