import React, { useContext, useState, useEffect } from "react";
import { makeStyles, unstable_createMuiStrictModeTheme } from '@material-ui/core/styles';
import { GiBlackball } from "react-icons/gi";
import Button from '@material-ui/core/Button';
import RemoveUserDialog from "./RemoveUserDialog";
import UsersTable from "./UsersTable";
import axios from "axios";


const useStyles = makeStyles(() => ({
  adminMain: {
    /* height: '100vh',
    width: '100vw', */
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    //paddingLeft: '5vw',
  },
  title: {
    marginBottom: '2%',
  }
}));


function Admin() {


  const classes = useStyles();

  const [usersData, setUsersData] = useState([]);

  const [alertsData, setAlertsData] = useState([]);

  const [operationsData, setOperationsData] = useState([]);


  useEffect(() => {
    fetchUsersData();
    fetchAlertsData();
    fetchOperationsData();
  }, []);

  //Fetch usersData for the first time
  const fetchUsersData = async () => {
    axios
      .get(
        "https://meetingpointaveiro.2bconnect.pt/teste/php/Users/all-users.php"
      )
      .then((res) => {
        setUsersData(res.data.users);
        console.log(res.data.users);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchAlertsData = async () => {
    axios
      .get(
        "https://meetingpointaveiro.2bconnect.pt/teste/php/middleware/read-mpalerts.php"
      )
      .then((res) => {
        setAlertsData(res.data.alerts);
        console.log(res.data.alerts);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchOperationsData = async () => {
    axios
      .get(
        "https://meetingpointaveiro.2bconnect.pt/teste/php/middleware/read-mplogs.php"
      )
      .then((res) => {
        setOperationsData(res.data.logs);
        console.log(res.data.logs);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  return (

    <main className={classes.adminMain}>


      <UsersTable fetchUsersData={fetchUsersData}  fetchAlertsData={fetchAlertsData}  fetchOperationsData={fetchOperationsData} usersData={usersData} alertsData={alertsData} operationsData={operationsData}/>



    </main>


  )
}

export default Admin;