import React from "react";

import "../styles/Footer.css";


//Footer Component
function Footer() {
  return <footer className="footerReports">Copyright © 2020 2bWebConnect</footer>;
}

export default Footer;
