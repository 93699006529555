import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(() => ({
  buttons: {
    marginBottom: '15px',
    backgroundColor: 'LawnGreen',
    '&:hover': {
      backgroundColor: 'lime'
    }
  },
  selectRole: {
    marginTop: '15px',
    marginBottom: '15px'
  },
  warning: {
    color: 'red',
    fontWeight: 'bold',
  }
}));

function AddUserDialog(props) {

  const [open, setOpen] = useState(false);

  const [fullname, setFullname] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [warning, setWarning] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setWarning(false);
    setOpen(false);
  };

  const handleChange = (event) => {

    switch (event.target.id) {
      case 'fullname':
        setFullname(event.target.value);
        break;
      case 'username':
        setUsername(event.target.value);
        break;
      case 'password':
        setPassword(event.target.value);
        break;      
    }

    if ((event.target.value == "user") || (event.target.value == "admin")) {
      setRole(event.target.value);
    }
  }

  function validateForm() {

    if ((fullname == "") || (username == "") || (password == "") || (role == "")) {
      console.log("Tem de preencher todos os campos!");
      setWarning(true);
      return true;
    }

  }


  function addUser() {

    console.log("ADD USER");

    if (validateForm()) {
      return;
    }

    axios.post("https://meetingpointaveiro.2bconnect.pt/teste/php/Users/add-user3.php", {
      name: fullname,
      user: username,
      password: password,
      role: role,
    })
      .then(function (response) {
        props.fetchUsersData();
      })
      .catch(function (error) {
        console.log(error);
      })

    handleClose();
  }

  const classes = useStyles();

  return (
    <div className={classes.buttonWrapper} style={{ textAlign: "center" }}>
      <Button variant="contained" className={classes.buttons} onClick={handleClickOpen}>
        Adicionar Utilizador
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Adicionar</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            id="fullname"
            label="Nome"
            type="text"
            value={fullname}
            onChange={handleChange}
            fullWidth
            required

          />
          <TextField
            autoFocus
            margin="dense"
            id="username"
            label="Nome de utilizador"
            type="text"
            value={username}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Palavra-passe"
            type="password"
            value={password}
            onChange={handleChange}
            fullWidth
            required
          />
          <div className={classes.selectRole}>
            <InputLabel id="roleId">Tipo</InputLabel>
            <Select
              labelId="roleId"
              id="role"
              value={role}
              onChange={handleChange}
            >
              <MenuItem value={"admin"}>Administrador</MenuItem>
              <MenuItem value={"user"}>Utilizador</MenuItem>
            </Select>
          </div>

          {warning ? 
            <div className={classes.warning}>
              Tem de preencher todos os campos antes de submeter!
            </div>
                  :
            ""
          }
          

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
          <Button onClick={addUser} color="primary">
            Submeter
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddUserDialog;