//Store is important to store global state in global variable
//Please read about useContext on React before try to change anything here. 

import { useState } from "react";

const useGlobalStore = () => {
  const [state, setState] = useState({
    listEvent: 1,
    id: 0,
    emergencyFlag: false,
    popUp: false,
    resetSystem: false,
    name: "",
    user: "",
    isAdmin: false,
  });

  const actions = (action) => {
    const { type, payload } = action;
    switch (type) {
      case "setState":
        return setState(payload);
      default:
        return state;
    }
  };
  return { state, actions };
};

export default useGlobalStore;
