import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Context from '../store/context';
import '../styles/Button.css';
import '../styles/CardArea.css';
import '../styles/Home.css';
import '../styles/Search.css';
import CardArea from './CardArea';
import ConfigBox from './ConfigBox.js';
import DisplayMessage from './DisplayMessage';
import ProgressBar from './ProgressBar';
import SearchList from './SearchList';

function Home() {
	const { state } = useContext(Context);
	//Hook for Fetching
	const [worker, setWorker] = useState([]);
	const [load, setLoad] = useState(false);
	const [fakeCurrentDate, setFakeCurrentDate] = useState(new Date());
	const [selectedCommands, setSelectedCommands] = useState(
		selectedCommandsObject
	);
	//This "1" is a number with no meaning. Refactor needed.
	const [submitAllUpdated, setSubmitAllUpdated] = useState(1);

	//This is just to create the object inside the state. THERE HAS TO BE A BETTER WAY!!
	var selectedCommandsObject = {
		3: '',
		4: '',
		5: '',
		6: '',
		7: '',
		8: '',
	};

	//setTimeout(function(){ console.log(selectedCommands); }, 2000);

	//Fetch Data for the first time
	const fetchWorkers = async () => {
		axios
			.get(
				'https://meetingpointaveiro.2bconnect.pt/api/registered_cards/read.php'
			)
			.then((res) => {
				setWorker(res.data.workers.map((el) => el));
				setLoad(true);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	//UseEffect to Fecth Data for the first time
	useEffect(() => {
		setInterval(() => fetchWorkers(), 10000);
	}, []);

	//UseEffect to check if emergenyFlag or resetSystem are activated. Checks every second
	//If emergyFlag or ResetSystem are true. The system fecth data and forces re-render.
	useEffect(() => {
		setTimeout(() => setFakeCurrentDate(new Date()), 1000);
		if (state.emergencyFlag || state.resetSystem) {
			fetchWorkers();
		}

		return () => console.log('cleanup');
	}, [fakeCurrentDate]);

	//Variable for filter the data from DB by Worker in each Meeting Point
	const area0 = worker.filter((worker) => worker.mp == 0);
	const area1 = worker.filter((worker) => worker.mp == 1);
	const area2 = worker.filter((worker) => worker.mp == 2);
	const area3 = worker.filter((worker) => worker.mp == 3);
	const area4 = worker.filter((worker) => worker.mp == 4);
	const area5 = worker.filter((worker) => worker.mp == 5);

	//Number of workers registered on Meeting Point
	const totalSafeWorkers =
		area1.length +
		area2.length +
		area3.length +
		area4.length +
		area5.length;

	return (
		<div class='content-wrap'>
			{/* Progress Bar and Props to Component*/}
			<div className='progressBarContainer'>
			{/* 	{load ? (
					 */}<ProgressBar
						safe={totalSafeWorkers}
						totalWorkers={worker.length}
						notSafe={area0.length}
					/>
			{/* 	) : (
					<h1 className='loading-alert'>Loading...</h1>
				)} */}
			</div>

			{/* Show search box*/}
			<div className='searchContainer'>
				{/* Search List Component and information from fetchData passed as prop to Component*/}
				<SearchList search={worker} updateData={fetchWorkers} />
			</div>

			<div>
				<div className='header-config'>
					{/* Component to Display Message */}
					<DisplayMessage message={'ZONAS PONTO DE ENCONTRO'} />
				</div>

				{/* Show Cards With Information of How Many Workers are per Meeting Point */}

				<div className='box-details'>
					<CardArea area={area1} byArea={1} />

					{/* <CardArea area={area2} byArea={2} name={'Estacionamento'} />

					<CardArea area={area3} byArea={3} name={'Oficinas'} />

					<CardArea area={area4} byArea={4} name={'Estaleiros'} />

					<CardArea area={area5} byArea={5} name={'Brigadistas'} /> */}
				</div>
			</div>

			<div className='header-config'>
				{/* Component to Display Message */}
				<DisplayMessage message={'CONFIGURAÇÃO'} />
			</div>

			<Grid className='box-details' container spacing={1}>
				<Grid item xs={6} sm={4} md={4} lg={2} xl={2}>
					<ConfigBox
						number={1}
						mp={12}
						onChange={setSelectedCommands}
						selectedCommands={selectedCommands}
						submitAllUpdated={submitAllUpdated}
						disableEndEmergencyRadio={false}
					/>
				</Grid>

				{/* <Grid item xs={6} sm={4} md={4} lg={2} xl={2}>
					<ConfigBox
						number={2}
						name={'Estacionamento'}
						mp={4}
						onChange={setSelectedCommands}
						selectedCommands={selectedCommands}
						submitAllUpdated={submitAllUpdated}
						disableEndEmergencyRadio={false}
					/>
				</Grid>

				<Grid item xs={6} sm={4} md={4} lg={2} xl={2}>
					<ConfigBox
						number={3}
						name={'Oficinas'}
						mp={5}
						onChange={setSelectedCommands}
						selectedCommands={selectedCommands}
						submitAllUpdated={submitAllUpdated}
						disableEndEmergencyRadio={false}
					/>
				</Grid>

				<Grid item xs={6} sm={4} md={4} lg={2} xl={2}>
					<ConfigBox
						number={4}
						name={'Estaleiros'}
						mp={6}
						onChange={setSelectedCommands}
						selectedCommands={selectedCommands}
						submitAllUpdated={submitAllUpdated}
						disableEndEmergencyRadio={false}
					/>
				</Grid>

				<Grid item xs={6} sm={4} md={4} lg={2} xl={2}>
					<ConfigBox
						number={5}
						name={'Brigadistas'}
						mp={7}
						onChange={setSelectedCommands}
						selectedCommands={selectedCommands}
						submitAllUpdated={submitAllUpdated}
						disableEndEmergencyRadio={false}
					/>
				</Grid>

				<Grid item xs={6} sm={4} md={4} lg={2} xl={2}>
					<ConfigBoxSubmitAll
						name={'End Point'}
						mp={8}
						onChange={setSelectedCommands}
						selectedCommands={selectedCommands}
						submitAllUpdated={submitAllUpdated}
						disableEndEmergencyRadio={false}
					/>
				</Grid> */}
			</Grid>

			{/* <SubmitAll
				selectedCommands={selectedCommands}
				submitAllUpdated={submitAllUpdated}
				setSubmitAllUpdated={setSubmitAllUpdated}
			/> */}
		</div>
	);
}

export default Home;
