import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import '../styles/Gvir.css';

const Gvir = () => {
	const useStyles = makeStyles({
		root: {
			width: '70%',
			maxWidth: '30%',
			marginBottom: '15px',
			'@media (max-width: 1134px)': {
				maxWidth: '80%',
			},
			'@media (max-width: 514px)': {
				maxWidth: '99%',
			},
		},
		table: {
			minWidth: 200,
		},
		rows: {
			color: 'black',
		},
		head: {
			color: 'white',
			backgroundColor: 'black',
		},
		dataGrid: {
			width: '50%',
			marginBottom: '4rem',
		},
		submitButton: {
			width: '100%',
			marginTop: '1rem',
			color: '#094b9b',
			border: '1px solid #094b9b',
			'&:focus': {},
		},
	});

	const [gvirData, setGvirData] = useState([]);

	const [date, setDate] = useState('');

	const [rows, setRows] = useState([]);

	const [columns, setColumns] = useState([]);

	const [loading, setLoading] = useState(true);

	const [thresholdH1, setThresholdH1] = useState();

	const [thresholdH2, setThresholdH2] = useState();

	useEffect(() => {
		setLoading(true);
		fetchGvirStatus().then(() => {
			setThresholdH1(gvirData.thresholdH1);
			setThresholdH2(gvirData.thresholdH2);
			setLoading(false);
		});
		console.log('fetchGvirStatus()');
	}, []);

	useEffect(() => {
		if (gvirData) {
			setRows([
				{ id: 1, property: 'LoRa ID', value: gvirData.id },
				{
					id: 2,
					property: 'Serial Number',
					value: gvirData.serialNumber,
				},
				{ id: 3, property: 'Last Contact', value: date.replace('.000000', '') },
				{ id: 4, property: 'Status', value: gvirData.status },
				{
					id: 5,
					property: 'Gas Concentration',
					value: gvirData.gasConcentration,
				},
				{
					id: 6,
					property: 'Sensor Temperature',
					value: gvirData.temperature,
				},
				{
					id: 7,
					property: 'Response Factor',
					value: gvirData.responseFactor,
				},
				{
					id: 8,
					property: 'Sensor Range',
					value: gvirData.sensorRange,
				},
				{ id: 9, property: 'Cal 100', value: gvirData.cal100 },
				{ id: 10, property: 'Cal 3000', value: gvirData.cal3000 },
				{
					id: 11,
					property: 'Threshold H1 (ppm)',
					value: gvirData.thresholdH1,
				},
				{
					id: 12,
					property: 'Threshold H2 (ppm)',
					value: gvirData.thresholdH2,
				},
			]);

			setColumns([
				{ field: 'property', headerName: 'Properties', flex: 1 },
				{
					field: 'value',
					headerName: 'GVIR Sensor values',
					flex: 1,
					editable: true,
				},
			]);
		}
	}, [gvirData, date]);

	//Fetch GvirStatus for the first time
	const fetchGvirStatus = async () => {
		await axios
			.get(
				'https://meetingpointaveiro.2bconnect.pt/api/gvir_status/read_last.php'
			)
			.then((res) => {
				setGvirData(res.data.sensorStatus);
				setDate(res.data.sensorStatus.dateTime.date);
			})
			.catch((err) => {
				console.log(err);
			});
			setThresholdH1(false);
			setThresholdH2(false);
	};

	const classes = useStyles();

	const handleRowEdition = async ({ id, field, value }) => {
		if (id === 11) setThresholdH1(value);

		if (id === 12) setThresholdH2(value);
	};

	const handleSubmission = async () => {
		let h1 = Number(thresholdH1)
		let h2 = Number(thresholdH2)
		let result
		let final = thresholdH2
		if (h2 <= h1) {
			result = h1 + 1
			final = result.toString()
		}
		console.log('h1: ' + thresholdH1)
		console.log('h2: ' + thresholdH2)
		await axios.post(
			'https://meetingpointaveiro.2bconnect.pt/api/gvir_operation/create.php',
			{
				equipmentId: gvirData.id,
				thresholdH1: thresholdH1,
				thresholdH2: final,
				order_given:  sessionStorage.getItem('user'),
			}
		);

		fetchGvirStatus();
	};

	return (
		<main className='gvir-main'>
			<h3 className='header-text'>GVIR SENSOR STATUS</h3>
			<div className={classes.dataGrid}>
				<DataGrid
					rows={rows}
					columns={columns}
					loading={loading}
					isCellEditable={(params) => params.row.id >= 11}
					onCellEditCommit={handleRowEdition}
					autoHeight
				/>

				<Button
					className={classes.submitButton}
					disabled={!thresholdH1 || !thresholdH2}
					onClick={handleSubmission}>
					Save
				</Button>
			</div>
		</main>
	);
};

export default Gvir;
