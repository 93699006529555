import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(() => ({
	buttons: {
		//maxWidth: '70px',
	},
	selectRole: {
		marginTop: '15px',
		marginBottom: '15px',
	},
	warning: {
		color: 'red',
		fontWeight: 'bold',
	},
}));

function EditUserDialog(props) {
	/* useEffect(() => {
        props.fetchUsersData();       
    }, []);

    useEffect(() => {
        console.log("usersData: " + props.usersData[0].name);
        setFullname(props.usersData[0].name);
    }, props.usersData); */

	useEffect(() => {
		getUserDataProps();
	}, []);

	function getUserDataProps() {
		setFullname(props.name);
		setUsername(props.user);
		setRole(props.role);
		setId(props.id);
	}

	const [open, setOpen] = useState(false);

	const [fullname, setFullname] = useState('');
	const [username, setUsername] = useState('');
	const [role, setRole] = useState('');
	const [id, setId] = useState('');
	const [warning, setWarning] = useState(false);

	const handleClickOpen = () => {
		getUserDataProps();
		setOpen(true);
	};

	const handleClose = () => {
		setWarning(false);
		setOpen(false);
	};

	const handleChange = (event) => {
		console.log('handle change: ' + event.target.id);
		console.log(event);
		console.log('handle change: ' + event.target.value);

		switch (event.target.id) {
			case 'fullname':
				setFullname(event.target.value);
				break;
			case 'username':
				setUsername(event.target.value);
				break;
		}

		if (event.target.value == 'user' || event.target.value == 'admin') {
			setRole(event.target.value);
		}
	};

	function validateForm() {
		if (fullname == '' || username == '' || role == '') {
			console.log('Tem de preencher todos os campos!');
			setWarning(true);
			return true;
		}
	}

	function editUser() {
		console.log('EDIT USER');

		if (validateForm()) {
			return;
		}

		axios
			.post(
				'https://meetingpointaveiro.2bconnect.pt/teste/php/Users/edit-user.php',
				{
					name: fullname,
					user: username,
					role: role,
					id: id,
				}
			)
			.then(function (response) {
				props.fetchUsersData();
			})
			.catch(function (error) {
				console.log(error);
			});

		handleClose();
	}

	const classes = useStyles();

	return (
		<span className={classes.buttonWrapper}>
			<Button
				size='small'
				color='primary'
				variant='contained'
				className={classes.buttons}
				onClick={handleClickOpen}>
				Editar
			</Button>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='form-dialog-title'>
				<DialogTitle id='form-dialog-title'>Editar</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						margin='dense'
						id='fullname'
						label='Nome'
						type='text'
						value={fullname}
						onChange={handleChange}
						fullWidth
						required
					/>
					<TextField
						autoFocus
						margin='dense'
						id='username'
						label='Nome de utilizador'
						type='text'
						value={username}
						onChange={handleChange}
						fullWidth
						required
					/>

					<div className={classes.selectRole}>
						<InputLabel id='roleId'>Tipo</InputLabel>
						<Select
							labelId='roleId'
							id='role'
							value={role}
							onChange={handleChange}>
							<MenuItem value={'admin'}>Administrador</MenuItem>
							<MenuItem value={'user'}>Utilizador</MenuItem>
						</Select>
					</div>

					{warning ? (
						<div className={classes.warning}>
							Tem de preencher todos os campos antes de editar!
						</div>
					) : (
						''
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color='primary'>
						Cancel
					</Button>
					<Button color='primary' onClick={editUser}>
						Edit
					</Button>
				</DialogActions>
			</Dialog>
		</span>
	);
}

export default EditUserDialog;
