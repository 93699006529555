
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Context from "../../store/context";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import TopBarIcons from '../TopBarIcons';
import { Autorenew, MicNone } from '@material-ui/icons';
import { AiOutlineDashboard } from 'react-icons/ai';
import { RiNpmjsLine } from 'react-icons/ri';
import { GiWhiteBook } from 'react-icons/gi';
import { mergeClasses } from "@material-ui/styles";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginBottom: '3%',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        marginLeft: '1%',
        marginRight: '4%',
        fontSize: '0.9rem',
        fontFamily: 'monospace',
        color: 'white',
        '& a': {
            color: 'white',
        },
        textAlign: 'center', 
    },
    welcomeText: {
        '@media (max-width: 630px)': {
            display: 'none',
          },
    }
}));

export default function SimplerBar() {
    const classes = useStyles();

    const { state } = useContext(Context);

    function handleLogout(e) {
        // e.preventDefault();
        sessionStorage.clear();
        //window.history.pushState({ message: 'New State!' }, 'Login screen', 'http://localhost:3000/');
        /* window.location.reload(); */
        window.location = '/';
    }

    return (


        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <Link to="/" className={"dashboard-symbol"} ><AiOutlineDashboard /></Link>

                    <Link to="/">
                        <Typography variant="h6" className={classes.title}>
                            MEETING POINT
                        </Typography>
                    </Link>
                    {TopBarIcons.map((item, index) => {

                        let returnValue;

                        (() => {

                            //if admin is false there's no icon nor text
                            if (((sessionStorage.getItem('role') != 'admin') && (item.title === 'ADMIN'))) {
                                returnValue = null;
                                return;
                            }



                            returnValue = (

                                <div className={!(item.title === 'LOGOUT') ? "nav-item" : "nav-item-last"}>
                                    <div key={index} className={item.cName}>

                                        <Link to={item.path}
                                            onClick={
                                                () => {
                                                    if (item.title == 'LOGOUT') {
                                                        handleLogout();
                                                        //return true;
                                                    }
                                                    //return null
                                                }}
                                        >

                                            <div className="menu-icon-text">

                                                <span className="menu-icon">{item.icon}</span>
                                                <span className="menu-text">{item.title}</span>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            );




                        })()

                        return returnValue;


                    })}


                <div className={classes.welcomeText}>Bem-vindo, {sessionStorage.getItem('name')}</div>


                </Toolbar>
            </AppBar>
        </div>
    )
};
