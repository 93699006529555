import React, { useState, useEffect } from 'react';
import RegisterSound from './RegisterSound ';
import CleanSound from './CleanSound';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import axios from "axios";


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  container: {
    '@media (max-width: 2500px)': {
      maxWidth: '50vw',
    },
    '@media (max-width: 1250px)': {
      maxWidth: '65vw',
    },
    '@media (max-width: 1000px)': {
      maxWidth: '85vw',
    },
    '@media (max-width: 539px)': {
      maxWidth: '100vw',
    }
  },
});


export default function UsersTable(props) {
  const classes = useStyles();

  useEffect(() => {
    fetchStates();
  }, []);


  const [seconds, setSeconds] = useState([]);

  const [evacuation, setEvacuation] = useState([]);

  const [emergency, setEmergency] = useState([]);

  const [endOfEmergency, setEndOfEmergency] = useState([]);

  const fetchStates = async () => {
    axios
      .get(
        "https://meetingpointaveiro.2bconnect.pt/teste/php/middleware/get-state.php"
      )
      .then((res) => {
        setEvacuation(res.data.evacuation);
        setEmergency(res.data.emergency);
        setEndOfEmergency(res.data.endOfEmergency);
        setSeconds(res.data.seconds);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeSeconds = (position) => {
    for (let i = 0; i < seconds.length; i++) {
      if (seconds.indexOf(seconds[i]) != position) {
        seconds[i] = false;
      }
    }

    const updatedSeconds = seconds.map((item, index) =>
      index === position ? !item : item
    );

    setSeconds(updatedSeconds);

  }

  const handleChangeEmergency = (position) => {
    const updatedEmergency = emergency.map((item, index) =>
      index === position ? !item : item
    );

    setEmergency(updatedEmergency);
  }

  const handleChangeEvacuation = (position) => {
    const updatedEvacuation = evacuation.map((item, index) =>
      index === position ? !item : item
    );

    setEvacuation(updatedEvacuation);
  }

  const handleChangeEndOfEmergency = (position) => {
    const updatedEndOfEmergency = endOfEmergency.map((item, index) =>
      index === position ? !item : item
    );

    setEndOfEmergency(updatedEndOfEmergency);
  }

  const uncheckState = () => {
    setEvacuation(new Array(60).fill(false));
    setEmergency(new Array(60).fill(false));
    setEndOfEmergency(new Array(60).fill(false));
    setSeconds(new Array(4).fill(false));
  }

  return (

    <TableContainer className={classes.container} component={Paper}>
      <h2 className={classes.title} style={{ textAlign: "center" }}><b><u>Toques da Corneta</u></b></h2>
      <br></br>
      <br></br>
      <br></br>
      <h4 className={classes.title} style={{ textAlign: "center" }}>Escala (Segundos)</h4>
      <br></br>

      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">0.5</StyledTableCell>
            <StyledTableCell align="center">1</StyledTableCell>
            <StyledTableCell align="center">2</StyledTableCell>
            <StyledTableCell align="center">3</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          <StyledTableRow >
            <StyledTableCell align="center" component="th" scope="row">
              <input
                type="checkbox"
                defaultChecked={false}
                checked={seconds[0]}
                onChange={() => handleChangeSeconds(0)}
              />
            </StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={seconds[1]}
              onChange={() => handleChangeSeconds(1)}
            />
            </StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={seconds[2]}
              onChange={() => handleChangeSeconds(2)}
            />
            </StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={seconds[3]}
              onChange={() => handleChangeSeconds(3)}
            />
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
      <br></br>
      <br></br>
      <div className={classes.buttonWrapper} style={{ textAlign: "center" }}>
        <RegisterSound seconds={seconds} emergency={emergency} evacuation={evacuation} endOfEmergency={endOfEmergency} />&nbsp; &nbsp; &nbsp; &nbsp;<CleanSound uncheckState={uncheckState} />
      </div>
      <br></br>
      <br></br>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">T</StyledTableCell>
            <StyledTableCell align="center">Emergência</StyledTableCell>
            <StyledTableCell align="center">Evacuação</StyledTableCell>
            <StyledTableCell align="center">Fim de Emergência</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell align="center">0</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[0]}
              onChange={() => handleChangeEmergency(0)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[0]}
              onChange={() => handleChangeEvacuation(0)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[0]}
              onChange={() => handleChangeEndOfEmergency(0)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">1</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[1]}
              onChange={() => handleChangeEmergency(1)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[1]}
              onChange={() => handleChangeEvacuation(1)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[1]}
              onChange={() => handleChangeEndOfEmergency(1)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">2</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[2]}
              onChange={() => handleChangeEmergency(2)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[2]}
              onChange={() => handleChangeEvacuation(2)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[2]}
              onChange={() => handleChangeEndOfEmergency(2)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">3</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[3]}
              onChange={() => handleChangeEmergency(3)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[3]}
              onChange={() => handleChangeEvacuation(3)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[3]}
              onChange={() => handleChangeEndOfEmergency(3)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">4</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[4]}
              onChange={() => handleChangeEmergency(4)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[4]}
              onChange={() => handleChangeEvacuation(4)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[4]}
              onChange={() => handleChangeEndOfEmergency(4)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">5</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[5]}
              onChange={() => handleChangeEmergency(5)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[5]}
              onChange={() => handleChangeEvacuation(5)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[5]}
              onChange={() => handleChangeEndOfEmergency(5)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">6</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[6]}
              onChange={() => handleChangeEmergency(6)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[6]}
              onChange={() => handleChangeEvacuation(6)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[6]}
              onChange={() => handleChangeEndOfEmergency(6)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">7</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[7]}
              onChange={() => handleChangeEmergency(7)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[7]}
              onChange={() => handleChangeEvacuation(7)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[7]}
              onChange={() => handleChangeEndOfEmergency(7)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">8</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[8]}
              onChange={() => handleChangeEmergency(8)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[8]}
              onChange={() => handleChangeEvacuation(8)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[8]}
              onChange={() => handleChangeEndOfEmergency(8)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">9</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[9]}
              onChange={() => handleChangeEmergency(9)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[9]}
              onChange={() => handleChangeEvacuation(9)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[9]}
              onChange={() => handleChangeEndOfEmergency(9)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">10</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[10]}
              onChange={() => handleChangeEmergency(10)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[10]}
              onChange={() => handleChangeEvacuation(10)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[10]}
              onChange={() => handleChangeEndOfEmergency(10)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">11</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              checked={emergency[11]}
              onChange={() => handleChangeEmergency(11)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[11]}
              onChange={() => handleChangeEvacuation(11)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[11]}
              onChange={() => handleChangeEndOfEmergency(11)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">12</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[12]}
              onChange={() => handleChangeEmergency(12)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[12]}
              onChange={() => handleChangeEvacuation(12)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[12]}
              onChange={() => handleChangeEndOfEmergency(12)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">13</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[13]}
              onChange={() => handleChangeEmergency(13)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[13]}
              onChange={() => handleChangeEvacuation(13)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[13]}
              onChange={() => handleChangeEndOfEmergency(13)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">14</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[14]}
              onChange={() => handleChangeEmergency(14)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[14]}
              onChange={() => handleChangeEvacuation(14)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[14]}
              onChange={() => handleChangeEndOfEmergency(14)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">15</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              checked={emergency[15]}
              onChange={() => handleChangeEmergency(15)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[15]}
              onChange={() => handleChangeEvacuation(15)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[15]}
              onChange={() => handleChangeEndOfEmergency(15)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">16</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              checked={emergency[16]}
              onChange={() => handleChangeEmergency(16)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[16]}
              onChange={() => handleChangeEvacuation(16)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[16]}
              onChange={() => handleChangeEndOfEmergency(16)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">17</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[17]}
              onChange={() => handleChangeEmergency(17)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[17]}
              onChange={() => handleChangeEvacuation(17)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[17]}
              onChange={() => handleChangeEndOfEmergency(17)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">18</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[18]}
              onChange={() => handleChangeEmergency(18)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[18]}
              onChange={() => handleChangeEvacuation(18)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[18]}
              onChange={() => handleChangeEndOfEmergency(18)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">19</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[19]}
              onChange={() => handleChangeEmergency(19)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[19]}
              onChange={() => handleChangeEvacuation(19)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[19]}
              onChange={() => handleChangeEndOfEmergency(19)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">20</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[20]}
              onChange={() => handleChangeEmergency(20)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[20]}
              onChange={() => handleChangeEvacuation(20)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[20]}
              onChange={() => handleChangeEndOfEmergency(20)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">21</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[21]}
              onChange={() => handleChangeEmergency(21)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[21]}
              onChange={() => handleChangeEvacuation(21)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[21]}
              onChange={() => handleChangeEndOfEmergency(21)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">22</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[22]}
              onChange={() => handleChangeEmergency(22)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[22]}
              onChange={() => handleChangeEvacuation(22)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[22]}
              onChange={() => handleChangeEndOfEmergency(22)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">23</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[23]}
              onChange={() => handleChangeEmergency(23)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[23]}
              onChange={() => handleChangeEvacuation(23)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[23]}
              onChange={() => handleChangeEndOfEmergency(23)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">24</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[24]}
              onChange={() => handleChangeEmergency(24)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[24]}
              onChange={() => handleChangeEvacuation(24)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[24]}
              onChange={() => handleChangeEndOfEmergency(24)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">25</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[25]}
              onChange={() => handleChangeEmergency(25)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[25]}
              onChange={() => handleChangeEvacuation(25)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[25]}
              onChange={() => handleChangeEndOfEmergency(25)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">26</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[26]}
              onChange={() => handleChangeEmergency(26)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[26]}
              onChange={() => handleChangeEvacuation(26)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[26]}
              onChange={() => handleChangeEndOfEmergency(26)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">27</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[27]}
              onChange={() => handleChangeEmergency(27)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[27]}
              onChange={() => handleChangeEvacuation(27)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[27]}
              onChange={() => handleChangeEndOfEmergency(27)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">28</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[28]}
              onChange={() => handleChangeEmergency(28)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[28]}
              onChange={() => handleChangeEvacuation(28)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[28]}
              onChange={() => handleChangeEndOfEmergency(28)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">29</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[29]}
              onChange={() => handleChangeEmergency(29)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[29]}
              onChange={() => handleChangeEvacuation(29)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[29]}
              onChange={() => handleChangeEndOfEmergency(29)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">30</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[30]}
              onChange={() => handleChangeEmergency(30)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[30]}
              onChange={() => handleChangeEvacuation(30)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[30]}
              onChange={() => handleChangeEndOfEmergency(30)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">31</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[31]}
              onChange={() => handleChangeEmergency(31)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[31]}
              onChange={() => handleChangeEvacuation(31)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[31]}
              onChange={() => handleChangeEndOfEmergency(31)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">32</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[32]}
              onChange={() => handleChangeEmergency(32)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[32]}
              onChange={() => handleChangeEvacuation(32)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[32]}
              onChange={() => handleChangeEndOfEmergency(32)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">33</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[33]}
              onChange={() => handleChangeEmergency(33)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[33]}
              onChange={() => handleChangeEvacuation(33)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[33]}
              onChange={() => handleChangeEndOfEmergency(33)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">34</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[34]}
              onChange={() => handleChangeEmergency(34)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[34]}
              onChange={() => handleChangeEvacuation(34)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[34]}
              onChange={() => handleChangeEndOfEmergency(34)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">35</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[35]}
              onChange={() => handleChangeEmergency(35)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[35]}
              onChange={() => handleChangeEvacuation(35)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[35]}
              onChange={() => handleChangeEndOfEmergency(35)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">36</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[36]}
              onChange={() => handleChangeEmergency(36)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[36]}
              onChange={() => handleChangeEvacuation(36)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[36]}
              onChange={() => handleChangeEndOfEmergency(36)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">37</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[37]}
              onChange={() => handleChangeEmergency(37)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[37]}
              onChange={() => handleChangeEvacuation(37)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[37]}
              onChange={() => handleChangeEndOfEmergency(37)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">38</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[38]}
              onChange={() => handleChangeEmergency(38)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[38]}
              onChange={() => handleChangeEvacuation(38)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[38]}
              onChange={() => handleChangeEndOfEmergency(38)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">39</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[39]}
              onChange={() => handleChangeEmergency(39)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[39]}
              onChange={() => handleChangeEvacuation(39)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[39]}
              onChange={() => handleChangeEndOfEmergency(39)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">40</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[40]}
              onChange={() => handleChangeEmergency(40)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[40]}
              onChange={() => handleChangeEvacuation(40)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[40]}
              onChange={() => handleChangeEndOfEmergency(40)}
            /></StyledTableCell>
          </StyledTableRow><StyledTableRow>
            <StyledTableCell align="center">41</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[41]}
              onChange={() => handleChangeEmergency(41)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[41]}
              onChange={() => handleChangeEvacuation(41)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[41]}
              onChange={() => handleChangeEndOfEmergency(41)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">42</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[42]}
              onChange={() => handleChangeEmergency(42)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[42]}
              onChange={() => handleChangeEvacuation(42)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[42]}
              onChange={() => handleChangeEndOfEmergency(42)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">43</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[43]}
              onChange={() => handleChangeEmergency(43)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[43]}
              onChange={() => handleChangeEvacuation(43)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[43]}
              onChange={() => handleChangeEndOfEmergency(43)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">44</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[44]}
              onChange={() => handleChangeEmergency(44)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[44]}
              onChange={() => handleChangeEvacuation(44)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[44]}
              onChange={() => handleChangeEndOfEmergency(44)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">45</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[45]}
              onChange={() => handleChangeEmergency(45)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[45]}
              onChange={() => handleChangeEvacuation(45)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[45]}
              onChange={() => handleChangeEndOfEmergency(45)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">46</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[46]}
              onChange={() => handleChangeEmergency(46)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[46]}
              onChange={() => handleChangeEvacuation(46)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[46]}
              onChange={() => handleChangeEndOfEmergency(46)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">47</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[47]}
              onChange={() => handleChangeEmergency(47)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[47]}
              onChange={() => handleChangeEvacuation(47)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[47]}
              onChange={() => handleChangeEndOfEmergency(47)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">48</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[48]}
              onChange={() => handleChangeEmergency(48)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[48]}
              onChange={() => handleChangeEvacuation(48)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[48]}
              onChange={() => handleChangeEndOfEmergency(48)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">49</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[49]}
              onChange={() => handleChangeEmergency(49)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[49]}
              onChange={() => handleChangeEvacuation(49)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[49]}
              onChange={() => handleChangeEndOfEmergency(49)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">50</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[50]}
              onChange={() => handleChangeEmergency(50)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[50]}
              onChange={() => handleChangeEvacuation(50)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[50]}
              onChange={() => handleChangeEndOfEmergency(50)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">51</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[51]}
              onChange={() => handleChangeEmergency(51)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[51]}
              onChange={() => handleChangeEvacuation(51)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[51]}
              onChange={() => handleChangeEndOfEmergency(51)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">52</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[52]}
              onChange={() => handleChangeEmergency(52)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[52]}
              onChange={() => handleChangeEvacuation(52)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[52]}
              onChange={() => handleChangeEndOfEmergency(52)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">53</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[53]}
              onChange={() => handleChangeEmergency(53)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[53]}
              onChange={() => handleChangeEvacuation(53)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[53]}
              onChange={() => handleChangeEndOfEmergency(53)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">54</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[54]}
              onChange={() => handleChangeEmergency(54)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[54]}
              onChange={() => handleChangeEvacuation(54)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[54]}
              onChange={() => handleChangeEndOfEmergency(54)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">55</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[55]}
              onChange={() => handleChangeEmergency(55)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[55]}
              onChange={() => handleChangeEvacuation(55)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[55]}
              onChange={() => handleChangeEndOfEmergency(55)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">56</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[56]}
              onChange={() => handleChangeEmergency(56)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[56]}
              onChange={() => handleChangeEvacuation(56)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[56]}
              onChange={() => handleChangeEndOfEmergency(56)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">57</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[57]}
              onChange={() => handleChangeEmergency(57)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[57]}
              onChange={() => handleChangeEvacuation(57)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[57]}
              onChange={() => handleChangeEndOfEmergency(57)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">58</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[58]}
              onChange={() => handleChangeEmergency(58)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[58]}
              onChange={() => handleChangeEvacuation(58)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[58]}
              onChange={() => handleChangeEndOfEmergency(58)}
            /></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">59</StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={emergency[59]}
              onChange={() => handleChangeEmergency(59)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={evacuation[59]}
              onChange={() => handleChangeEvacuation(59)}
            /></StyledTableCell>
            <StyledTableCell align="center"><input
              type="checkbox"
              defaultChecked={false}
              checked={endOfEmergency[59]}
              onChange={() => handleChangeEndOfEmergency(59)}
            /></StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
      <br></br>
      <br></br>
      <br></br>
    </TableContainer>
  );
}