import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";

const useStyles = makeStyles(() => ({
  buttons: {
    /* marginBottom: '15px', */
    fontSize: '18px',
    backgroundColor: 'LawnGreen',
    '&:hover': {
      backgroundColor: 'lime'

    }
  },
  selectRole: {
    marginTop: '15px',
    marginBottom: '15px'
  },
  warning: {
    color: 'red',
    fontWeight: 'bold',
  }
}));

function RegisterSound(props) {

  const classes = useStyles();


  function register() {
    axios.post("https://meetingpointaveiro.2bconnect.pt/teste/php/middleware/register.php", {
      seconds: props.seconds,
      emergency: props.emergency,
      evacuation: props.evacuation,
      endOfEmergency: props.endOfEmergency,
    })
      .then(function (response) {
      //console.log(response)
    })
    .catch(function (error) {
      console.log(error);
    })

}

return (
/*   <div className={classes.buttonWrapper} style={{ textAlign: "center" }}> */
    <Button disabled={sessionStorage.getItem('role') != 'admin'} size="small" variant="contained" className={classes.buttons} onClick={() => { window.confirm('Pretende efectuar o registo?') && register() }}>
      Registar Sons
    </Button>

);

}

export default RegisterSound;