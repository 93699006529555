//Please read about React Router to understand this syntax.

import React from 'react';

import './App.css';
import TopAppBar from './Components/AppBar/AppBar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './Components/Home';
import Gvir from './Components/Gvir';
import WindSensor from './Components/WindSensor';
import Sound from './Components/Sound/Sound';
import useToken from './useToken';
import Login from './Components/Login';
import Admin from './Components/Admin/Admin';

import Footer from './Components/Footer';

function App() {
	//setToken: saves to state and local storage
	const { token, setToken } = useToken();

	// const token = getToken();

	if (!token) {
		return <Login setToken={setToken} />;
	}

	return (
		<>
			<div>
				<Router>
					<TopAppBar />
					<Switch>
						<Route exact path='/' exact component={Home} />
						<Route exact path='/sound' exact component={Sound} />
						<Route exact path='/gvir' exact component={Gvir} />
						<Route
							exact
							path='/wind'
							exact
							component={WindSensor}
						/>
						<Route exact path='/login' exact component={Login} />
						{sessionStorage.getItem('role') == 'admin' ? <Route exact path='/admin' exact component={Admin} /> : null}
					</Switch>
				</Router>
				<Footer />
			</div>
		</>
	);
}

export default App;
