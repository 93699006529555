import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(() => ({
  buttons: {
    marginBottom: '15px',
    backgroundColor: 'LawnGreen',
    '&:hover': {
      backgroundColor: 'lime'
    }
  },
  selectRole: {
    marginTop: '15px',
    marginBottom: '15px'
  },
  warning: {
    color: 'red',
    fontWeight: 'bold',
  }
}));

function AddUserDialog(props) {

  const [open, setOpen] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [alert, setAlert] = useState("");
  const [warning, setWarning] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setWarning(false);
    setOpen(false);
  };

  const handleChange = (event) => {

    switch (event.target.id) {
      case 'name':
        setName(event.target.value);
        break;
      case 'email':
        setEmail(event.target.value);
        break;
    }

    if ((event.target.value == "GVIR Threshold") || (event.target.value == "Outros")) {
      setAlert(event.target.value);
    }
  }

  function validateForm() {

    if ((name == "") || (email == "") || (alert == "")) {
      console.log("Tem de preencher todos os campos!");
      setWarning(true);
      return true;
    }

  }


  function addAlert() {

    console.log("ADD ALERT");

    if (validateForm()) {
      return;
    }

    axios.post("https://meetingpointaveiro.2bconnect.pt/teste/php/middleware/add-alert.php", {
      name: name,
      email: email,
      alert: alert,
    })
      .then(function (response) {
        props.fetchAlertsData();
      })
      .catch(function (error) {
        console.log(error);
      })

    handleClose();
  }

  const classes = useStyles();

  return (
    <div className={classes.buttonWrapper} style={{ textAlign: "center" }}>
      <Button variant="contained" className={classes.buttons} onClick={handleClickOpen}>
        Adicionar Alerta
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Adicionar</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nome"
            type="text"
            value={name}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            type="text"
            value={email}
            onChange={handleChange}
            fullWidth
            required
          />
          <div className={classes.selectRole}>
            <InputLabel id="alert">Tipo</InputLabel>
            <Select
              /* labelId="alert" */
              id="alert"
              value={alert}
              onChange={handleChange}
            >
              <MenuItem value={"GVIR Threshold"}>GVIR Threshold</MenuItem>
              <MenuItem value={"Outros"}>Outros</MenuItem>
            </Select>
          </div>

          {warning ? 
            <div className={classes.warning}>
              Tem de preencher todos os campos antes de submeter!
            </div>
                  :
            ""
          }
          

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
          <Button onClick={addAlert} color="primary">
            Submeter
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddUserDialog;