import React, { useContext, useState, useEffect } from "react";
import { makeStyles, unstable_createMuiStrictModeTheme } from '@material-ui/core/styles';
import { GiBlackball } from "react-icons/gi";
import Button from '@material-ui/core/Button';
import SoundTable from "./SoundTable";
import axios from "axios";


const useStyles = makeStyles(() => ({
  SoundMain: {
    /* height: '100vh',
    width: '100vw', */
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    //paddingLeft: '5vw',
  },
  title: {
    marginBottom: '2%',
  }
}));


function Sound() {


  const classes = useStyles();


  return (

    <main className={classes.SoundMain}>


      <SoundTable  />



    </main>


  )
}

export default Sound;